import { gql } from '@apollo/client';
import { STATUS } from 'components/helpers/constants';

// Helper function
export const omit = (keys, obj) =>
  Object.entries(obj)
    .filter(([key]) => !keys.includes(key))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

// Unique Rate Application Statuses and Types
export const APP_STATUS = {
  DRAFT: 'Draft',
  SUBMITTED: 'Submitted',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  REWORK: 'Rework',
};
export const STATUS_BLOCK_TYPE = {
  [APP_STATUS.APPROVED]: STATUS.ACTIVE,
  [APP_STATUS.REJECTED]: STATUS.EXPIRED,
  [APP_STATUS.REWORK]: STATUS.PENDING,
  [APP_STATUS.SUBMITTED]: STATUS.PENDING,
  [APP_STATUS.DRAFT]: STATUS.PENDING,
};
export const APP_TYPE = {
  APPLY: 'Unique rate request',
  ADD_VEHICLE: 'Add vehicles',
  REMOVE: 'Unique rate removal',
  // ADD: 'Add Rate',
  // MODIFY: 'Modify Rate',
};
export const APP_TYPE_LABEL = {
  [APP_TYPE.APPLY]: 'Unique rate request',
  [APP_TYPE.ADD_VEHICLE]: 'Add vehicles',
  [APP_TYPE.REMOVE]: 'Unique rate removal',
  // [APP_TYPE.ADD]: 'Unique rate request',
  // [APP_TYPE.MODIFY]: 'Unique rate request',
};
export const DEFAULT_APP_FILTER_STATE = { status: [APP_STATUS.SUBMITTED] };

// Stepper steps
export const STEPS = [
  {
    step: 1,
    title: 'Select Unique Rates and Upload MOA',
    status: ['current', 'not completed', 'not completed'],
  },
  {
    step: 2,
    title: 'Enter Customer and Vehicle Information',
    status: ['completed', 'current', 'not completed'],
  },
  {
    step: 3,
    title: 'Review Summary and Submit',
    status: ['completed', 'completed', 'current'],
  },
];

// Step one options
const selectOption = [
  {
    label: '-Select-',
    value: '',
  },
];

const REASON_OPTIONS = {
  'Maintenance only mileage rate': [
    ...selectOption,
    {
      label: 'Severe use',
      value: 'Severe use',
    },
    {
      label: 'Onsite fuel, No CSA',
      value: 'Onsite fuel, No CSA',
    },
    {
      label: 'Unique agency agreement',
      value: 'Unique agency agreement',
    },
  ],
  'Fuel only mileage rate': [
    ...selectOption,
    {
      label: 'Severe use',
      value: 'Severe use',
    },
    {
      label: 'Onsite M&R, No CSA',
      value: 'Onsite M&R, No CSA',
    },
    {
      label: 'Unique agency agreement',
      value: 'Unique agency agreement',
    },
  ],
  'No mileage rate': [
    ...selectOption,
    {
      label: 'Severe use fuel and M&R',
      value: 'Severe use fuel and M&R',
    },
    {
      label: 'Onsite fuel and M&R, no CSA',
      value: 'Onsite fuel and M&R, no CSA',
    },
    {
      label: 'Severe use fuel; Onsite M&R, No CSA',
      value: 'Severe use fuel; Onsite M&R, No CSA',
    },
    {
      label: 'Severe use M&R; Onsite fuel, No CSA',
      value: 'Severe use M&R; Onsite fuel, No CSA',
    },
    {
      label: 'Unique agency agreement',
      value: 'Unique agency agreement',
    },
  ],
  'Non-default assignment': [
    {
      label: 'Consolidation replacements',
      value: 'Consolidation replacements',
      defaultValue: true,
    },
    {
      label: 'Unique agency agreement',
      value: 'Unique agency agreement',
      defaultValue: true,
    },
  ],
  'Manual entry': [
    {
      label: 'Unique agency agreement',
      value: 'Unique agency agreement',
      defaultValue: true,
    },
  ],
};

export const MILEAGE_TYPE_OPTIONS = [
  ...selectOption,
  {
    label: 'Maintenance only mileage rate',
    value: 'Maintenance only mileage rate',
  },
  {
    label: 'Fuel only mileage rate',
    value: 'Fuel only mileage rate',
  },
  {
    label: 'No mileage rate',
    value: 'No mileage rate',
  },
];

export const MONTHLY_RATE_OPTIONS = [
  ...selectOption,
  {
    label: 'Non-default rate assignment',
    value: 'Non-default rate assignment',
  },
  {
    label: 'Manual entry',
    value: 'Manual entry',
  },
];

export const UNIQUE_RATE_OPTIONS = [
  ...selectOption,
  {
    label: 'Non-default assignment',
    value: 'Non-default assignment',
  },
  {
    label: 'Manual entry',
    value: 'Manual entry',
  },
];

export const GET_REASON_OPTIONS = (mileageType) => REASON_OPTIONS[mileageType] || [];

// Review request options
export const UNIQUE_RATE_REVIEW_OPTIONS = [
  ...selectOption,
  {
    label: 'Approve',
    value: 'Approved',
  },
  {
    label: 'Reject',
    value: 'Rejected',
  },
  {
    label: 'Request modification',
    value: 'Rework',
  },
];

const URAFields = `
      id
      mileageType
      mileageRate
      reason
      appType
      status
      justification
      comment
      monthlyRateType
      submissionDate
      actionDate
      billingMethod
      cardType
      deletedAt
      updatedByUser
      updatedAt
      updatedByUserInfo {
        fullName
        email
      }
      createdByUser
      createdAt
      createdByUserInfo {
        fullName
        email
      }
      dataSource
      dmlFlag
      uniqueRateAppPoc {
        customerId
        agencyCode
        bureauCode
        poc
        email
        phonenumber
      }
      uniqueRateAppFcs {
        address1
        address2
        address3
        city
        state
        zipcode
        zipcode4
      }
      uniqueRateAppVehicle {
        customerId
        vin
        plateNumber
        leaseRateCode
        monthlyRate
        mileageRate
      }
      supportingDocument {
        id
        model
        modelId
        documentName
        documentType
        metadataId
        metadata {
          id
          signedUrl
          name
          description
          docSource
          docStoreUri
          fileLocation
          fileMimeType
          scanStatus
          uploadCompletedAt
          size
        }
      }`;

// GraphQL Schemas
export const UniqueRateAppResponse = gql`
  fragment UniqueRateAppResponseFields on UniqueRateApp {
    ${URAFields}
  }
`;

export const PaginatedUniqueRateAppResponse = gql`
  fragment PaginatedUniqueRateAppFields on PaginatedUniqueRateAppResponse {
    rows {
      ${URAFields}
    }
    count
    hasMore
  }
`;

export const CREATE_OR_UPDATE_UNIQUE_RATE_APP = gql`
  mutation createOrUpdateUniqueRateApplications(
    $id: String!
    $uniqueRateAppInput: UniqueRateAppInput!
    $uniqueRateAppPocInput: UniqueRateAppPocInput!
    $uniqueRateAppFcsInput: UniqueRateAppFcsInput!
    $uniqueRateAppVehicleInput: [UniqueRateAppVehicleInput!]!
  ) {
    createOrUpdateUniqueRateApplications(
      id: $id
      uniqueRateAppInput: $uniqueRateAppInput
      uniqueRateAppPocInput: $uniqueRateAppPocInput
      uniqueRateAppFcsInput: $uniqueRateAppFcsInput
      uniqueRateAppVehicleInput: $uniqueRateAppVehicleInput
    ) {
      ...UniqueRateAppResponseFields
    }
  }
  ${UniqueRateAppResponse}
`;

export const GET_UNIQUE_RATE_APPS = gql`
  query getUniqueRateApplications(
    $filters: FilterType
    $pocFilters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getUniqueRateApplications(
      filters: $filters
      pocFilters: $pocFilters
      offset: $offset
      order: $order
      limit: $limit
    ) {
      ...PaginatedUniqueRateAppFields
    }
  }
  ${PaginatedUniqueRateAppResponse}
`;

export const GET_UNIQUE_RATE_APP_BY_ID = gql`
  query getUniqueRateApplicationById($id: String!) {
    getUniqueRateApplicationById(id: $id) {
      ...UniqueRateAppResponseFields
    }
  }
  ${UniqueRateAppResponse}
`;

export const DELETE_DRAFT_UNIQUE_RATE_APP = gql`
  mutation deleteDraftUniqueRateApp($id: String!) {
    deleteDraftUniqueRateApp(id: $id) {
      ...UniqueRateAppResponseFields
    }
  }
  ${UniqueRateAppResponse}
`;

export const GET_CUSTOMER_INFO_FROM_VMS = gql`
  query getCustomer($customerId: String!) {
    getCustomer(customerId: $customerId) {
      customerId
      firstName
      lastName
      customerAgencyCode
      customerBureauCode
      email
      phone
      address1
      address2
      address3
      city
      stateCode
      postalCode
      vehicles {
        tagNumber
        id
        vehicleLeaseRateCode
      }
    }
  }
`;

export const GET_CUSTOMER_VEHICLES_FROM_VMS = gql`
  query getCustomer($customerId: String!) {
    getCustomer(customerId: $customerId) {
      customerId
      vehicles {
        tagNumber
        id
        vehicleLeaseRateCode
      }
    }
  }
`;

export const UPDATE_UNIQUE_RATE_APP_FOR_REVIEW = gql`
  mutation updateUniqueRateAppForReview($uniqueRateAppReviewInput: UniqueRateAppReviewInput!) {
    updateUniqueRateAppForReview(uniqueRateAppReviewInput: $uniqueRateAppReviewInput) {
      id
      mileageType
      mileageRate
      reason
      appType
      status
      justification
      comment
      monthlyRateType
      submissionDate
      actionDate
      billingMethod
      cardType
      deletedAt
      updatedByUser
      updatedAt
      createdByUser
      createdAt
      dataSource
      dmlFlag
    }
  }
`;

export const GET_AGENCIES = gql`
  query getAgencies {
    getAgencies {
      id
      name
      shortName
    }
  }
`;

export const GET_CUSTOMER_AGENCIES = gql`
  query getCustomerAgencies {
    getCustomerAgencies {
      agencyCode
      agencyName
    }
  }
`;

export const GET_DISTINCT_AGENCY_CODE = gql`
  query getDistinctAgencyCode {
    getDistinctAgencyCode {
      agencyCode
    }
  }
`;

export const GET_AGENCY_BY_ID = gql`
  query getAgency($id: String!) {
    getAgency(id: $id) {
      id
      name
      shortName
    }
  }
`;
export const GET_BUREAUS = gql`
  query getBureaus($agencyCode: String!) {
    getBureaus(agencyCode: $agencyCode) {
      id
      name
      shortName
      agencyCode
      agencyIndicatorCode
    }
  }
`;
export const GET_BUREAU_BY_AGENCY_AND_ID = gql`
  query getBureau($agencyCode: String!, $id: String!) {
    getBureau(agencyCode: $agencyCode, id: $id) {
      id
      name
      shortName
      agencyCode
      agency {
        name
        shortName
      }
    }
  }
`;

export const GET_DISTINCT_CUSTOMER_ID = gql`
  query getDistinctCustomerId {
    getDistinctCustomerId {
      customerId
    }
  }
`;

export const GET_STATES = gql`
  query GetStates {
    getStates {
      stateName
      stateCode
    }
  }
`;

export const GET_SUPPORTING_DOC_URL = gql`
  mutation uploadSupportingBMDocument($docInput: DocInput!) {
    uploadSupportingBMDocument(docInput: $docInput) {
      id
      model
      modelId
      metadataId
      documentType
      documentName
      metadata {
        name
        description
        docSource
        docStoreUri
        fileLocation
        fileMimeType
        scanStatus
        size
        uploadCompletedAt
        creator {
          fullName
        }
      }
      signedUrl
    }
  }
`;

export const DELETE_SUPPORTING_DOC = gql`
  mutation deleteSupportingBMDocument($id: String!) {
    deleteSupportingBMDocument(id: $id)
  }
`;

export const GET_AWS_SIGNED_READ_URL = gql`
  query GenerateReadSignedURL($fileKey: String!, $fileType: String!) {
    generateReadSignedURL(fileKey: $fileKey, fileType: $fileType)
  }
`;

export const COPY_SUPPORTING_DOC = gql`
  mutation copySupportingBMDocument($docInput: DocInput!, $sourceMetadataId: String!) {
    copySupportingBMDocument(docInput: $docInput, sourceMetadataId: $sourceMetadataId) {
      id
      model
      modelId
      metadataId
      documentType
      documentName
      metadata {
        name
        description
        docSource
        docStoreUri
        fileLocation
        fileMimeType
        scanStatus
        size
        uploadCompletedAt
        creator {
          fullName
        }
      }
    }
  }
`;
