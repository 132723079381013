import { gql } from '@apollo/client';

export const GET_ACQUISITION_DATA_BY_FY = gql`
  query getAcquisitionDataByFYForSpentBalance($FiscalYear: String!) {
    getAcquisitionDataByFYForSpentBalance(fiscalYear: $FiscalYear) {
      amountSpent {
        replacementFunding
        consolidationFunding
        additionalFunding
      }
      amountBalance {
        replacementFunding
        consolidationFunding
        additionalFunding
      }
    }
  }
`;
