import { getCurrentFiscalYear } from 'utilities/dateUtils';
import { renderAgencyFilterItem } from './agency-filter-item';

const fiscalYear = getCurrentFiscalYear();
const fiscalYearOptions = [fiscalYear-1, fiscalYear, fiscalYear+1];
const fiscalYearOptionsFormatted = fiscalYearOptions.map((year) => ({
  value: year,
  label: year,
  defaultValue: year === fiscalYear,
}));

export const generateAFVFundingFilterStructure = (
  customerAgenciesList,
) => {

  const options = customerAgenciesList?.map((d) => ({
    value: d.agencyCode,
    label: `(${d.agencyCode}) - ${d.name}`,
  }));

  return [
    {
      title: 'Fiscal year',
      key: '$fiscal_year$',
      type: 'select',
      operator: 'EQ',
      hideClear: true,
      options: [{ value: '', label: '-Select-' }, ...fiscalYearOptionsFormatted],
    },
    {
      key: '$agency_code$',
      title: 'Agency',
      component: renderAgencyFilterItem(options),
      permanent: false,
      operator: '$in',
      value: [],
    },
  ];
};