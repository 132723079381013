/* eslint-disable prefer-destructuring */
/* eslint-disable radix */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { useState } from 'react';
import { createContainer } from 'react-tracked';
import _ from 'lodash/fp';
import { mutableMerge, safeParseFloat } from 'utilities/formatUtils';
import { setProxyState, useStateBuilder } from 'utilities/stateUtils';
import { getAlertContent, isTierRate, trimRate } from '../helper';

const commonPageStateConfig = {
  currentPage: 'Ancillary Service Fees',
  bannerMsgState: { type: null, content: null },
  welcomeMessage:
    'You may use the ancillary service fees page to filter and update the rates associated with these services. You may also create new services and add the corresponding rate structure.',
};

const rateStructures = ['Rate structure', 'Tiered rate structure'];

const categoryStateConfig = {
  categoryFilter: {
    rateStatus: [`Active`],
    filter: {
      optInCategoryName: null,
    },
    offset: 0,
    currentPage: 1,
    limit: 10,
    order: [['optInCategoryName', 'DESC']],
    options: {
      optInCategoryName: [],
    },
    isFullyLoaded: false,
    isLoading: false,
  },
  category_toggleActiveForm: {
    reason: '',
  },
  category_updateOptInCategoryForm: {
    prevOptInCategoryName: '',
  },
  category_toggleActiveModal: {
    show: false,
    isDeactivate: true,
  },
  category_updateOptInCategoryModal: {
    show: false,
    isCreate: true,
  },
  category_table: {
    rowData: {
      optInCategoryName: '',
      optInCategoryDesc: '',
    },
    rows: [],
  },
  category_itemsCount: 0,
  category_modalAlert: { type: null, content: null },
};

const serviceStateConfig = {
  serviceFilter: {
    rateStatus: [`Active`],
    offset: 0,
    currentPage: 1,
    limit: 10,
    order: [
      ['startDate', 'DESC'],
    ],
    filter: {
      optInSvcCategory: null,
    },
    options: {
      optInSvcCategory: [],
    },
    isFullyLoaded: false,
    isLoading: false,
  },
  service_toggleActiveForm: {
    reason: '',
  },
  service_updateOptInCategoryForm: {
    prevOptInCategoryName: '',
  },
  service_toggleActiveModal: {
    show: false,
    isDeactivate: true,
  },
  service_deleteModal: {
    show: false,
  },
  service_updateOptInServiceModal: {
    show: false,
    isCreate: true,
  },
  distinctOptInSalesCodeOptions: [],
  distinctServiceName: [],
  service_table: {
    originalFormState: {
      startDate: '',
      optInSvcDesc: '',
      optInSvcSalesId: '',
      rateStructure: null,
      oneTimeRate: {},
      recurringRate: {},
      tierRate: { tierRateRows: [] },
    },
    rowData: {
      formData: null,
      isCreate: false,
      optInSvcId: '',
      rateStatus: '',
      optInSvcName: '',
      startDate: null,
      optInSvcDesc: '',
      optInSvcCategory: null,
      optInSvcSalesId: null,
      optInServiceAssociationObj: [],
      error: {
        startDate: false,
      },
      optInServiceType: { optInSvcCategory: '', optInSvcName: '' },
      // for optin service form
      rateStructure: rateStructures[0],

      oneTimeRate: { checkBox: false, rateType: '$', rateValue: '', frequency: 'One-time' },
      recurringRate: { checkBox: false, rateType: '$', rateValue: '', frequency: 'Monthly' },
      tierRate: {
        bannerMsgState: { type: null, content: null },
        selectedLowerRangeFloat: -1.0,
        isEdit: false,
        lowerRange: '',
        rateValue: '',
        rateType: '$',
        ratesRows: [{ lowerRange: 0, rateValue: '0', rateType: '$' }],
      },
    },
    rows: [],
  },
  service_itemsCount: 0,
  service_modalAlert: { type: null, content: null },
};

const initialState = _.flow(mutableMerge(commonPageStateConfig), mutableMerge(serviceStateConfig))(categoryStateConfig);

export const DISPATCH_FNS = {
  reset(draft) {
    Object.assign(draft, { ...initialState, bannerMsgState: draft.bannerMsgState });
  },

  bannerMsgState: {
    setTypeContent(draft, { type, content }) {
      draft.bannerMsgState.type = type;
      draft.bannerMsgState.content = content;
    },
  },
  updateFilterLoadingStatus(draft, { filterName, loadingStatus }) {
    draft[`${filterName}`].isFullyLoaded = !loadingStatus;
    draft[`${filterName}`].isLoading = loadingStatus;
  },

  setItemPerPage(draft, { limit, resetPaginationComponent, filterName }, dispatchFns) {
    draft[`${filterName}`].limit = limit;
    dispatchFns.resetPagination(draft, { resetPaginationComponent, filterName });
  },
  resetPagination(draft, { resetPaginationComponent, filterName }) {
    draft[`${filterName}`].currentPage = 1;
    draft[`${filterName}`].offset = 0;
    resetPaginationComponent();
  },

  updateStateByPath(draft, { path, data }) {
    setProxyState(draft, path, data);
  },

  calculateCategoryOffset(draft) {
    return (draft.category_currentPage - 1) * draft.category_limit;
  },
  updateCategoryRateStatus(draft, { rateStatus }) {
    draft.categoryFilter.rateStatus = _.values(rateStatus);
  },
  toggleActiveModal(draft, { show, openModal, closeModal, isCategory = true }) {
    if (isCategory) {
      draft.category_toggleActiveModal.show = show;
    } else {
      draft.service_toggleActiveModal.show = show;
    }
    show ? openModal() : closeModal();
  },
  category_updateOptInCategoryModal(draft, { show, openModal, closeModal }) {
    draft.category_updateOptInCategoryModal.show = show;
    if (show) {
      openModal();
      return;
    }
    draft.category_modalAlert = { type: null, content: null };
    closeModal();
  },
  service_updateOptInServiceModal(draft, { isCreate = false, show, openModal, closeModal }) {
    draft.service_updateOptInServiceModal = { show, isCreate };
    if (show) {
      openModal();
      draft.service_table.rowData.isCreate = isCreate;
      return;
    }
    draft.service_modalAlert = { type: null, content: null };
    closeModal();
  },
  updateModalAlert(draft, { type, content, isCategory = true }) {
    if (isCategory) {
      draft.category_modalAlert = { type, content };
    } else {
      draft.service_modalAlert = { type, content };
    }
  },
  category_resetToggleActiveForm(draft) {
    draft.category_toggleActiveForm.reason = '';
  },
  updateServiceRowData(draft, { rowData }) {
    draft.service_table.rowData = { ...initialState.service_table.rowData, ...rowData };
  },
  // back up the formstate and trim to a uniq format for dirty check
  service_createOriginalFormState(draft) {
    draft.service_table.originalFormState = {
      optInSvcDesc: draft.service_table.rowData.optInSvcDesc,
      optInSvcSalesId: draft.service_table.rowData.optInSvcSalesId,
      rateStructure: draft.service_table.rowData.rateStructure,
      oneTimeRate: trimRate(draft.service_table.rowData.oneTimeRate),
      recurringRate: trimRate(draft.service_table.rowData.recurringRate),
      tierRate: { tierRateRows: _.map(trimRate, draft.service_table.rowData.tierRate.ratesRows) },
    };
  },
  service_updateRateStructure(draft, { rowData }) {
    // clean up rowData to make sure optInSvcDesc is '' instead of null
    if (!rowData.optInSvcDesc) {
      rowData.optInSvcDesc = '';
    }
    // override rowData state by the rowData from the BE
    draft.service_table.rowData = { ...initialState.service_table.rowData, ...rowData };

    // if isTierRate then override service_table.rowData.tierRate
    if (isTierRate(rowData.optInServiceAssociationObj)) {
      draft.service_table.rowData.rateStructure = rateStructures[1];
      draft.service_table.rowData.tierRate = {
        ...initialState.service_table.rowData.tierRate,
        ratesRows: _.map((x) => {
          return {
            rateType: x.optInFlatDollar ? '$' : '%',
            rateValue: x.optInFlatDollar ? x.optInFlatDollar : x.optInFixedRate * 100,
            lowerRange: parseInt(x.tierLowerRange),
          };
        }, rowData.optInServiceAssociationObj),
      };
    } else {
      const rateStructureList = _.map((x) => {
        const retObj = {
          checkBox: true,
          rateType: x.optInFlatDollar ? '$' : '%',
          rateValue: x.optInFlatDollar ? x.optInFlatDollar : x.optInFixedRate * 100,
          frequency: x.optInBillingFreq,
        };
        if (x.optInBillingFreq === 'One-time') {
          return { ...retObj, isOneTimeRate: true };
        }
        if (x.optInBillingFreq !== 'One-time' && x.optInBillingFreq !== 'Varied-rate') {
          return {
            ...retObj,
            isRecurringRate: true,
          };
        }
        return null;
      }, rowData.optInServiceAssociationObj);

      const oneTimeRate = _.find('isOneTimeRate', rateStructureList);
      const recurringRate = _.find('isRecurringRate', rateStructureList);
      if (oneTimeRate) {
        draft.service_table.rowData.oneTimeRate = oneTimeRate;
      }
      if (recurringRate) {
        draft.service_table.rowData.recurringRate = recurringRate;
      }
    }
  },
  service_updateDeleteModal(draft, { show, openModal, closeModal }) {
    draft.service_deleteModal.show = show;
    show ? openModal() : closeModal();
  },
  service_updateTierRateRow(draft, { currentRow }) {
    // if selectedLowerRangeFloat == 0 the input will change to read only
    const floatLowerRange = safeParseFloat(currentRow.lowerRange, 2);
    draft.service_table.rowData.tierRate.selectedLowerRangeFloat = floatLowerRange;
    draft.service_table.rowData.tierRate.lowerRange = floatLowerRange;
    draft.service_table.rowData.tierRate.rateValue = currentRow.rateValue;
    draft.service_table.rowData.tierRate.rateType = currentRow.rateType;
    draft.service_table.rowData.tierRate.isEdit = true;
  },

  service_resetTierRateInput(draft) {
    const initValue = { selectedLowerRangeFloat: -1.0, isEdit: false, lowerRange: '', rateValue: '', rateType: '$' };
    draft.service_table.rowData.tierRate = { ...draft.service_table.rowData.tierRate, ...initValue };
  },

  service_toggleRowDataError(draft, { isError }, dispatchFns) {
    draft.service_table.rowData.error.startDate = isError;
    dispatchFns.updateModalAlert(draft, {
      type: isError ? 'error' : null,
      content: _.getOr(
        null,
        'message',
        isError
          ? { message: `A ${draft.service_table?.rowData?.optInServiceType?.optInSvcName} rate already exists for the start date` }
          : {},
      ),
      isCategory: false,
    });
  },

  service_editTierRate(draft, { currentIndex, isCreate = false, isDelete = false }, dispatchFns) {
    const { lowerRange, rateValue, rateType } = draft.service_table.rowData.tierRate;
    const replaceCharNum = isCreate ? 0 : 1;
    isDelete
      ? draft.service_table.rowData.tierRate.ratesRows.splice(currentIndex, 1)
      : draft.service_table.rowData.tierRate.ratesRows.splice(currentIndex, replaceCharNum, {
          lowerRange: safeParseFloat(lowerRange, 2),
          rateValue: safeParseFloat(rateValue, 2),
          rateType,
        });

    dispatchFns.service_resetTierRateInput(draft);

    draft.service_table.rowData.tierRate.bannerMsgState.content = getAlertContent(isCreate, isDelete);
  },
  service_beforeSubmitOptInServiceForm(draft, { formData, isCreate }) {
    draft.service_table.rowData.formData = formData;
    draft.service_table.rowData.isCreate = isCreate;
  },
  updateFilter(draft, { itemName, data, resetPaginationComponent, filterName }, dispatchFns) {
    dispatchFns.resetPagination(draft, { resetPaginationComponent, filterName });

    if (itemName === 'rateStatus') {
      draft[`${filterName}`].rateStatus = data;
    } else {
      draft[`${filterName}`].filter[`${itemName}`] = data;
    }
  },
};

const useValue = () => useState(initialState);

const { Provider, useTrackedState, useUpdate: useSetState } = createContainer(useValue);

const useDispatch = () => useStateBuilder(useSetState, DISPATCH_FNS);

export { initialState, Provider, useTrackedState, useDispatch, rateStructures };
