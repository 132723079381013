import React from 'react';
import { TabSet } from '@gsa/afp-component-library';
import AFPTable from 'widgets/afp-table-wrapper';
import { stateValue } from 'utilities/stateUtils';
import balanceTableColumns from '../model/schemas/balanceTableSchema';
import HistoryTable from './HistoryTable';

/* eslint-disable react/prop-types */
const AcquisitionTabs = ({ pageState, setDispatch, DISPATCH_FNS, tabLoading, fetchRows }) => {
  const balanceRowsData = stateValue(pageState.balanceRows);
  const historyRowsData = stateValue(pageState.historyTab.historyRows);
  const { balanceLoading, historyLoading } = tabLoading;

  return (
    <div id="Acquisition-Tabs" data-testid="Acquisition-Tabs">
      <TabSet
        tabs={[
          {
            heading: 'Balance',
            content: (
              <div id="balance-tab" className="grid-row">
                <div className="grid-col-12">
                  <AFPTable
                    isLoading={balanceLoading}
                    columns={balanceTableColumns}
                    data={balanceRowsData}
                    NoDataMessages={{
                      title: 'No Data Available',
                      text: `There's no data available`,
                    }}
                    fullWidth
                  />
                </div>
              </div>
            ),
            tabSelectedWhenOpen: pageState.selectedTabId === 'balance-tab',
          },
          {
            heading: 'Funding history',
            content: (
              <div id="history-tab" className="grid-row">
                <HistoryTable
                  pageState={pageState}
                  setDispatch={setDispatch}
                  DISPATCH_FNS={DISPATCH_FNS}
                  fetchRows={fetchRows}
                  historyLoading={historyLoading}
                  historyRowsData={historyRowsData}
                />
              </div>
            ),
            tabSelectedWhenOpen: pageState.selectedTabId === 'history-tab',
          },
        ]}
      />
    </div>
  );
};

export default AcquisitionTabs;
