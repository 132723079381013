import React from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';

const { FilterPanelFilterShape, FilterPanelField } = FilterPanel;

export const renderAgencyFilterItem = (options) => {
  const item = ({ filter }) => {
    
    return (
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'multiselectdropdown',
          placeholder: '- Select agency -',
          options: [
            ...options,
          ],
        }}
      />
    );
  };

  item.propTypes = {
    filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
  };

  return item;
};
