import React from 'react';
import { Alert } from '@gsa/afp-component-library';
import { hasValue } from 'utilities/formatUtils';
import { EditModal } from 'components/modals/edit-modal/edit-modal';
import LoadingQueryResult from 'components/wrappers/LoadingQueryResult';
import CategoryToggleActiveForm from './category/ToggleActiveForm';
import ServiceToggleActiveForm from './service/ToggleActiveForm';

/* eslint-disable react/prop-types */

export const ToggleActiveModal = ({
  onClose,
  disabled,
  isDeactivate,
  pageState,
  loading,
  isCategoryPage = true,
  ...formProps
}) => {
  const extraDesc = () => {
    if (isCategoryPage) {
      return (
        <>
          <b>{pageState.category_table?.rowData?.optInServiceType?.optInCategoryName}</b>. Please provide a reason for{' '}
        </>
      );
    }

    return (
      <>
        <b>{pageState.service_table?.rowData?.optInServiceType?.optInSvcName}</b>. Please provide a reason for{' '}
      </>
    );
  };
  const isShowAlert = isCategoryPage ? !!pageState.category_modalAlert.content : !!pageState.service_modalAlert.content;
  const AlertContent = isCategoryPage ? (
    <Alert slim focused={false} type={pageState.category_modalAlert.type} style={{ marginBottom: 18 }}>
      {pageState.category_modalAlert.content}
    </Alert>
  ) : (
    <Alert slim focused={false} type={pageState.service_modalAlert.type} style={{ marginBottom: 18 }}>
      {pageState.service_modalAlert.content}
    </Alert>
  );
  return (
    <EditModal
      id="opt-in-toggle-active-modal"
      title={`${isDeactivate ? 'Deactivate' : 'Reactivate'} a Service${isCategoryPage ? ' Category' : ''}`}
      onClose={onClose}
      variant="large"
      customizeButtonText={isDeactivate ? 'Deactivate' : 'Reactivate'}
      customizeButtonProps={{
        type: 'submit',
        form: formProps?.formId,
        disabled: hasValue()(loading),
        variant: isDeactivate && 'secondary',
      }}
      showAlert={isShowAlert}
      alert={AlertContent}
    >
      <p className="margin-top-0 margin-bottom-5" data-testid="toggle-active-modal-body">
        You are about to {isDeactivate ? 'deactivate' : 'reactivate'} {extraDesc()}
        {isDeactivate ? 'deactivation' : 'reactivation'} in the box below.
      </p>

      <LoadingQueryResult loadingObject={loading}>
        {isCategoryPage ? (
          <CategoryToggleActiveForm {...formProps} isDeactivate={isDeactivate} pageState={pageState} />
        ) : (
          <ServiceToggleActiveForm {...formProps} isDeactivate={isDeactivate} pageState={pageState} />
        )}
      </LoadingQueryResult>
    </EditModal>
  );
};
