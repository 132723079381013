import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Label,
  Textbox,
  ErrorMessage,
  DatePicker,
  Button,
  Icon,
  Checkbox,
  MultiSelectDropdown,
} from '@gsa/afp-component-library';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import {
  formatDollar,
  validateDollar,
  classNameErrBox,
  classNameErrInput,
  classNameErrDatePicker,
} from 'components/helpers/afp-bm-helpers';
import BannerMessage from 'widgets/banner-message';
import { getTomorrowStr, getUTCDateStr, UTCDateStrToUS, USDateStrToUTC } from 'components/helpers/afp-bm-date';
import RequiredNotation from 'components/widgets/required-notation';
import useCharCounter from 'components/widgets/char-counter';
import { AFV_SURCHARGE_ACTION, CREATE_OR_UPDATE_AFV_FUNDING } from '../afv-funding-helper';
import GET_ENABLED_BM_FEATURES from '../../../featureQuery';
import '../style/afv-funding-set-surcharge.scss';

const COMMENT_MAX_LEN = 1000;
const tomorrowStr = getTomorrowStr();

// Form validation schema
const schema = yup.object().shape({
  afvSurchargeAmount: yup
    .string()
    .matches(/(^\d{0,16}\.?\d{0,4}$)|(^\d{0,16}\.?\d{0,4}$)/, 'Surcharge amount must be a number')
    .required('Surcharge is a required field'),
  startDate: yup
    .date()
    .transform((value) => (moment(value).isValid() ? value : null))
    .nullable()
    .required('Start date is required field'),
  comment: yup
    .string()
    .max(COMMENT_MAX_LEN, `Comments cannot exceed ${COMMENT_MAX_LEN} characters`)
    .nullable()
    .required('Comments is a required field'),
  requestCustomerConcurrence: yup.string().nullable(),
  notifyCustomer: yup.string().nullable(),
  requestCustomerConcurrenceUpdate: yup.string().nullable(),
  notifyCustomerUpdate: yup.string().nullable(),
});

const AFVFundingSetSurcharge = ({
  afvFundingData,
  surchargeData,
  onModalClose,
  onSetSurchargeBannerMsg,
  refetchSurcharges,
  calcSurcharge,
}) => {
  const [modalBannerMsg, setModalBannerMsg] = useState(null);
  const [showRecipient, setShowRecipient] = useState(!surchargeData);
  const [emailOptions, setEmailOptions] = useState([]);
  const [showAfvSection, setShowAfvSection] = useState(null);


  const [CharCounter, setCount] = useCharCounter(COMMENT_MAX_LEN);

  const bannerRef = useRef(null);

  const getDefaultStartDate = () => getUTCDateStr(moment().add(1, 'M').startOf('month').toString());

  const onSetModalBannerMsg = (banner) => {
    setModalBannerMsg(banner);
    bannerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const [getBMFeatures, { data: bmEnabledFeature }] = useLazyQuery(
    GET_ENABLED_BM_FEATURES,{fetchPolicy: 'network-only'},
  );
  
  const showAFVSectionFeature = (dataBmEnabledFeature) =>  {
    let showSection = true;
    if (dataBmEnabledFeature && dataBmEnabledFeature.getEnabledBMFeatures) {
      const { getEnabledBMFeatures } = dataBmEnabledFeature;
      if (getEnabledBMFeatures.includes('HIDE_AFV_SECTION')) {
        showSection = false;
      }
    }
    return showSection;
  };

  const { control, errors, getValues, setValue, setError, clearErrors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      afvSurchargeAmount: surchargeData ? parseFloat(surchargeData.surcharge).toFixed(2) : '',
      startDate: surchargeData ? surchargeData.startDate : getDefaultStartDate(),
      comment: '',
      requestCustomerConcurrence: true,
      notifyCustomer: false,
      requestCustomerConcurrenceUpdate: false,
      notifyCustomerUpdate: false,
      emailsTo: [],
      emailsCc: [],
    },
  });

  // useEffect callbacks
  useEffect(() => {
    // TODO - fetch list of emails from CC based on user role & scope
    const emailList = ['john.smith@gsa.gov', 'jane.doe@gsa.gov'];
    setEmailOptions(emailList.map((e) => ({ label: e, value: e })));
    getBMFeatures(); 
  }, []);

  useEffect(() => {
    setShowAfvSection(showAFVSectionFeature(bmEnabledFeature));
  }, [bmEnabledFeature]);
   

  useEffect(() => {
    // set-new-surcharge block - clear form when refresh
    if (afvFundingData) {
      setValue('afvSurchargeAmount', '');
      setValue('comment', '');
      setValue('requestCustomerConcurrence', true);
      setValue('notifyCustomer', false);
      setCount(0);
      setShowRecipient(true);
    }
  }, [afvFundingData]);

  useEffect(() => {
    // update calculated surcharge amount
    if (afvFundingData && calcSurcharge?.surcharge) {
      const calcSurchargeAmout = parseFloat(calcSurcharge.surcharge) > 0 ? calcSurcharge.surcharge : '0.00';
      setValue('afvSurchargeAmount', calcSurchargeAmout);
    }
  }, [calcSurcharge]);

  // gql calls
  const [createOrUpdateAfvFunding, { loading: loadingAfvFunding }] = useMutation(CREATE_OR_UPDATE_AFV_FUNDING, {
    onCompleted: (res) => {
      if (res) {
        if (surchargeData) onModalClose();
        refetchSurcharges();

        const {
          surcharge: responseAfvFundingSurcharge,
          startDate: responseAfvFundingStartDate,
        } = res.createOrUpdateAfvFunding;

        let message;
        if (surchargeData) {
          const { requestCustomerConcurrenceUpdate: concur, notifyCustomerUpdate: notify } = getValues();
          message = (
            <div>
              You have successfully updated the surcharge rate.
              {(concur || notify) && ' Notification has been sent to the customer.'}
            </div>
          );
        } else {
          const { requestCustomerConcurrence } = getValues();
          message = requestCustomerConcurrence ? (
            <div>
              A new surcharge rate of {formatDollar(responseAfvFundingSurcharge)} scheduled to take effect on{' '}
              {UTCDateStrToUS(responseAfvFundingStartDate)} has been recommended to the customer.
            </div>
          ) : (
            <div>
              A new surcharge rate of {formatDollar(responseAfvFundingSurcharge)} will take effect on{' '}
              {UTCDateStrToUS(responseAfvFundingStartDate)}.
            </div>
          );
        }

        onSetSurchargeBannerMsg({
          type: 'success',
          message,
        });
      }
    },
    onError: (err) => {
      const errMsg = {
        type: 'error',
        message: (
          <div>
            Error occurred when saving a surcharge
            <br />
            {err.message}
          </div>
        ),
      };
      surchargeData ? onSetModalBannerMsg(errMsg) : onSetSurchargeBannerMsg(errMsg);
    },
  });

  const createErrorMessage = (err) => (err ? <ErrorMessage>{err.message}</ErrorMessage> : null);

  const onSubmit = () => {
    const {
      afvSurchargeAmount,
      startDate,
      comment,
      requestCustomerConcurrence,
      requestCustomerConcurrenceUpdate,
      emailsTo,
      emailsCc,
    } = getValues();

    // check emails if required
    if (showAfvSection && showRecipient && !emailsTo?.length) {
      setError(
        'emailsTo',
        {
          type: 'manual',
          message: 'Recipient is required',
        },
        { shouldFocus: true },
      );
      return;
    }

    let variables = null;
     if (afvFundingData) {
      // Creating a new surcharge
      const { agencyCode, agencyIndicator, surcharges } = afvFundingData;

      // check for duplicated startDate
      if (surcharges?.find((sur) => sur.startDate === startDate)) {
        onSetSurchargeBannerMsg({
          type: 'error',
          message: 'The new surcharge has the same start date as an existing surcharge. Please change the date.',
        });
        setError(
          'startDate',
          {
            type: 'manual',
            message: 'A surcharge already exists for the start date',
          },
          { shouldFocus: true },
        );
        return;
      }

      const actionData = requestCustomerConcurrence ? AFV_SURCHARGE_ACTION.RECOMMEND : AFV_SURCHARGE_ACTION.SET;

      variables = {
        id: '',
        afvFundingInput: {
          agencyCode,
          agencyIndicator,
          startDate,
          surcharge: afvSurchargeAmount,
          isFinalized: actionData === AFV_SURCHARGE_ACTION.SET,
        },
        afvRateCommentInput: {
          afvSurchargeAmount,
          comment,
          startDate,
          action: actionData,
          emailsTo,
          emailsCc,
          responseId: null,
          afvFundingId: null,
        },
      };
    }

    if (surchargeData) {
      // Updating an existing surcharge
      const { id: afvFundingId, agencyCode, agencyIndicator } = surchargeData;
      const actionData = requestCustomerConcurrenceUpdate
        ? AFV_SURCHARGE_ACTION.RECOMMEND
        : AFV_SURCHARGE_ACTION.APPROVE;

      variables = {
        id: afvFundingId,
        afvFundingInput: {
          agencyCode,
          agencyIndicator,
          startDate,
          surcharge: afvSurchargeAmount,
          isFinalized: surchargeData.isFinalized || !requestCustomerConcurrenceUpdate,
        },
        afvRateCommentInput: {
          afvSurchargeAmount,
          comment,
          startDate,
          action: actionData,
          emailsTo,
          emailsCc,
          responseId: null,
          afvFundingId,
        },
      };
    }

    if (variables) createOrUpdateAfvFunding({ variables });
  };

  const onCheckboxChange = (fieldToUncheck, checked) => {
    setValue(fieldToUncheck, false);
    setShowRecipient(checked);
  };

  const onCommentChange = (e) => {
    const { value } = e.target;
    setCount(value.length);
  };

  return (
    <div>
      <div ref={bannerRef}>
        {modalBannerMsg && (
          <BannerMessage className="margin-top-2" type={modalBannerMsg.type} onClose={() => setModalBannerMsg(null)}>
            {modalBannerMsg.message}
          </BannerMessage>
        )}
      </div>
      {!surchargeData && <h2 className="margin-bottom-0">Set New Surcharge</h2>}
      <p>
        <RequiredNotation />
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="margin-bottom-3"
        id={surchargeData ? 'update-surcharge-form' : 'set-surcharge-form'}
      >
        <div className="form-ra margin-bottom-6">
          <div className="grid-container padding-0">
            <div className={`${surchargeData ? 'grid-col' : 'grid-row'} margin-bottom-3`}>
              <div className={`${surchargeData ? 'padding-bottom-4' : 'grid-col-7'}`}>
                <div className="grid-row grid-gap bm-form-row margin-bottom-3">
                  <div className={`${surchargeData ? 'grid-col-6' : 'grid-col-5'}`}>
                    <Controller
                      control={control}
                      name="afvSurchargeAmount"
                      data-testid="afvSurchargeAmount"
                      render={({ name, value }) => (
                        <div className={`usa-form-group bm-form-row-input ${classNameErrBox(errors[name])}`}>
                          <Label htmlFor="surcharge-amount" required>
                            {surchargeData ? 'Surcharge' : 'New surcharge'}
                          </Label>
                          {createErrorMessage(errors[name])}
                          <Icon
                            iconName="attach_money"
                            className={`bm-prefix-icon-dollar ${errors[name] && 'bm-prefix-icon-dollar-error'}`}
                          />
                          <Textbox
                            id="surcharge-amount"
                            className={classNameErrInput(errors[name])}
                            name={name}
                            value={formatDollar(value, '', -1)}
                            onChange={(e) => {
                              if (!e.target.value) setValue('afvSurchargeAmount', '');
                              const val = e.target.value?.split(',').join('');
                              if (validateDollar(val)) setValue('afvSurchargeAmount', val);
                            }}
                            data-testid={name}
                            aria-label="New surcharge amount input"
                          />
                        </div>
                      )}
                    />
                  </div>
                  <div className="grid-col-6" style={{ minWidth: 300 }}>
                    <Controller
                      control={control}
                      name="startDate"
                      defaultValue={surchargeData?.startDate || getDefaultStartDate()}
                      render={({ name, value, onChange, onBlur }) => (
                        <div className={`usa-form-group bm-form-row-input ${classNameErrBox(errors[name])}`}>
                          <Label htmlFor="start-date" required>
                            Start date
                          </Label>
                          {createErrorMessage(errors[name])}
                          <DatePicker
                            id="start-date"
                            className={classNameErrDatePicker(errors[name])}
                            name={name}
                            placeholder={UTCDateStrToUS(value)}
                            disabled={!afvFundingData}
                            minDate={
                              !afvFundingData && surchargeData?.startDate < tomorrowStr ? undefined : tomorrowStr
                            }
                            defaultValue={value || getDefaultStartDate()}
                            onChange={(val) => {
                              onChange(val ? USDateStrToUTC(val) : null);
                            }}
                            onBlur={onBlur}
                            data-testid={name}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="grid-row grid-gap">
                  <div className={`${surchargeData ? 'grid-col-12' : 'grid-col-11'}`}>
                    <Controller
                      control={control}
                      name="comment"
                      render={({ name, value, onChange, onBlur }) => (
                        <div className={`usa-form-group ${classNameErrBox(errors[name])}`}>
                          <Label htmlFor="comments" required>
                            Comments
                          </Label>
                          {createErrorMessage(errors[name])}
                          <Textbox
                            id="comments"
                            className={classNameErrInput(errors[name])}
                            name={name}
                            data-testid={name}
                            value={value}
                            maxLength={COMMENT_MAX_LEN}
                            type="textarea"
                            rows={3}
                            aria-label="Comments"
                            onChange={(e) => {
                              onChange(e);
                              onCommentChange(e);
                            }}
                            onBlur={onBlur}
                          />
                          {CharCounter}
                        </div>
                      )}
                    />
                  </div>
                </div>

                {showAfvSection && surchargeData && (
                  <div className="grid-row grid-gap margin-top-1">
                    <div className={`grid-col-6 ${surchargeData.isFinalized ? 'display-none' : ''}`}>
                      <Controller
                        control={control}
                        name="requestCustomerConcurrenceUpdate"
                        render={({ name, value, onChange, onBlur }) => (
                          <Checkbox
                            id="request-customer-concurrence-update"
                            name={name}
                            checked={value}
                            label="Request customer concurrence"
                            onChange={(e) => {
                              onChange(e.target.checked);
                              onCheckboxChange('notifyCustomerUpdate', e.target.checked);
                            }}
                            onBlur={onBlur}
                            aria-label="Request customer concurrence checkbox"
                          />
                        )}
                      />
                    </div>
                    <div className="grid-col-6">
                      <Controller
                        control={control}
                        name="notifyCustomerUpdate"
                        render={({ name, value, onChange, onBlur }) => (
                          <Checkbox
                            id="notify-customer-update"
                            name={name}
                            checked={value}
                            label="Notify customer"
                            onChange={(e) => {
                              onChange(e.target.checked);
                              onCheckboxChange('requestCustomerConcurrenceUpdate', e.target.checked);
                            }}
                            onBlur={onBlur}
                            aria-label="Notify customer checkbox"
                          />
                        )}
                      />
                    </div>
                  </div>
                )}

                {showAfvSection && afvFundingData && (
                  <div className="grid-row grid-gap margin-top-1">
                    <div className="grid-col-5">
                      <Controller
                        control={control}
                        name="requestCustomerConcurrence"
                        render={({ name, value, onChange, onBlur }) => (
                          <Checkbox
                            id="request-customer-concurrence"
                            data-testid="request-customer-concurrence"
                            name={name}
                            checked={value}
                            label="Request customer concurrence"
                            onChange={(e) => {
                              onChange(e.target.checked);
                              onCheckboxChange('notifyCustomer', e.target.checked);
                            }}
                            onBlur={onBlur}
                            aria-label="Request customer concurrence checkbox"
                          />
                        )}
                      />
                    </div>
                    <div className="grid-col-6">
                      <Controller
                        control={control}
                        name="notifyCustomer"
                        render={({ name, value, onChange, onBlur }) => (
                          <Checkbox
                            id="notify-customer"
                            data-testid="notify-customer"
                            name={name}
                            checked={value}
                            label="Notify customer"
                            onChange={(e) => {
                              onChange(e.target.checked);
                              onCheckboxChange('requestCustomerConcurrence', e.target.checked);
                            }}
                            onBlur={onBlur}
                            aria-label="Notify customer checkbox"
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>

              {showAfvSection && showRecipient && (
                <div
                  style={surchargeData ? { borderTop: '1px solid #a9aeb1' } : { borderLeft: '1px solid #a9aeb1' }}
                  className={`${surchargeData ? 'padding-top-4' : 'grid-col-5'}`}
                >
                  <div className={surchargeData ? '' : 'margin-left-4'}>
                    <div className="bm-section-header">Recipients</div>
                    <Controller
                      control={control}
                      name="emailsTo"
                      render={({ name, value, onChange }) => (
                        <div className={`usa-form-group ${classNameErrBox(errors[name])}`}>
                          <Label htmlFor="select-emails-to">
                            To
                          </Label>
                          {createErrorMessage(errors[name])}
                          <MultiSelectDropdown
                            id="select-emails-to"
                            options={emailOptions}
                            name={name}
                            selectedValues={value}
                            label="Notify customer"
                            onChange={(val) => {
                              onChange(val);
                              if (val?.length) clearErrors(name);
                              else if (value?.length)
                                setError(name, {
                                  type: 'manual',
                                  message: 'Recipient is required',
                                });
                            }}
                            aria-label="Select email addresses to send notification to"
                          />
                        </div>
                      )}
                    />

                    <Controller
                      control={control}
                      name="emailsCc"
                      render={({ name, value, onChange }) => (
                        <div className={`usa-form-group ${classNameErrBox(errors[name])}`}>
                          <Label htmlFor="select-emails-cc">Cc</Label>
                          <MultiSelectDropdown
                            id="select-emails-cc"
                            options={emailOptions}
                            name={name}
                            selectedValues={value}
                            onChange={onChange}
                            aria-label="Select email addresses to copy notification to"
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
            {afvFundingData && (
              <div id="save-btn">
                <Button
                  variant="primary"
                  type="submit"
                  form="set-surcharge-form"
                  disabled={loadingAfvFunding}
                  label="Save"
                />
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

AFVFundingSetSurcharge.defaultProps = {
  afvFundingData: null,
  surchargeData: null,
  calcSurcharge: null,
  onModalClose: undefined,
  refetchSurcharges: undefined,
};

AFVFundingSetSurcharge.propTypes = {
  afvFundingData: PropTypes.shape({
    agencyCode: PropTypes.string,
    agencyIndicator: PropTypes.string,
    surcharges: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  surchargeData: PropTypes.shape({
    id: PropTypes.string,
    surcharge: PropTypes.string,
    agencyCode: PropTypes.string,
    agencyIndicator: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    comment: PropTypes.string,
    status: PropTypes.string,
    isFinalized: PropTypes.bool,
  }),
  onModalClose: PropTypes.func,
  onSetSurchargeBannerMsg: PropTypes.func.isRequired,
  refetchSurcharges: PropTypes.func,
  calcSurcharge: PropTypes.shape({
    surcharge: PropTypes.string,
  }),
};

export default AFVFundingSetSurcharge;
