import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Button } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { ENTITY } from 'components/role-permission/role-permission';
import { genRowActions, getActions } from 'components/role-permission/row-action';
import AfpTable from 'widgets/afp-table-wrapper';
import exportCSVFile from 'components/file-export/client-side-csv-export';
import { formatDollar } from 'components/helpers/afp-bm-helpers';
import {
  SITE_ADMIN_ROLE,
  ORDERING_ADMIN_ROLE,
  OVERSIGHT_BUSINESS_ANALYST_ROLE,
} from 'pages/wallet-details/forms/consts';
import AFVFundingSidebar from './filters';
import AFVFundingFilterProvider from './filters/filter-provider';
import { GET_AGENCIES, GET_BUREAUS, GET_CUSTOMER_AGENCIES } from '../unique-rate-app/unique-rate-app-helper';
import {
  CURRENT_FY,
  GET_FINALIZED_AFV_SURCHARGES,
  GET_ALL_AMOUNT_FUNDED,
  GET_AMOUNT_COLLECTED_FOR_BILLING,
  GET_MOCK_DATA_FOR_STORE,
  GET_DATA_FOR_VMS,
  MergeAgencyData,
} from './afv-funding-helper';
import './style/afv-funding-table.scss';

const ROLE_ENTITY = ENTITY.AFV_SURCHARGE;
const ACTIONS = getActions(ROLE_ENTITY);
const ROW_ACTIONS = {
  current: [ACTIONS.MANAGE],
  past: [ACTIONS.VIEW],
};

const DEFAULT_FILTER_STATE = { fiscalYear: CURRENT_FY };
const DEFAULT_ORDER = [['agencyCode', 'ASC']];
const DEFAULT_INDICATOR = 'N/A';
const DEFAULT_CSV_ORDER_BY = 'agencyCode';
 
const AFVFundingPage = ({ setBannerMsg }) => {
  const [agencyList, setAgencyList] = useState([]);
  const [customerAgencies, setCustomerAgencies] = useState([]);
  const [fiscalYear, setFiscalYear] = useState(CURRENT_FY);
  const [filters, setFilters] = useState(DEFAULT_FILTER_STATE);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [data, setData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showFilters, setShowFilters] = useState(true);

  // AFP-162028 get current user
  const { currentUser } = useCurrentUser();
  const adminRoles = [
    SITE_ADMIN_ROLE,
    ORDERING_ADMIN_ROLE,
    OVERSIGHT_BUSINESS_ANALYST_ROLE,
  ];
  const isAdminRole = currentUser.roles.some(
    (role) => adminRoles.indexOf(`${role.id}`) !== -1,
  );
  const isOrderingAdminRole = currentUser.roles.some(
    (role) => `${role.id}` === ORDERING_ADMIN_ROLE,
  );
  
  const viewOnly = !isAdminRole;
  const history = useHistory();

  if (!history?.location?.search) {
    history.replace(`/bm/afv-funding?filter-fiscal_year=${CURRENT_FY}`);
  }

  const onSort = (val) => {
    const res = val.split(' ');
    setOrder([[...res[0].split('`')[1].split('.'), res[1]]]);
  };

  const [fetchAgencies, { data: dataAgencies, loading: loadingAgencies, error: errorAgencies }] = useLazyQuery(
    GET_AGENCIES,
  );
  const [fetchBureaus, { data: dataBureaus, loading: loadingBureaus, error: errorBureaus }] = useLazyQuery(GET_BUREAUS);
  const [fetchCustomerAgencies, { data: dataCustomerAgencies, loading: loadingCustomerAgencies, error: errorCustomerAgencies }] = useLazyQuery(
    GET_CUSTOMER_AGENCIES,
  );
  const [fetchSurcharges, { data: dataSurcharges, loading: loadingSurcharges, error: errorSurcharges }] = useLazyQuery(
    GET_FINALIZED_AFV_SURCHARGES,
  );
  const [
    fetchAmountFunded,
    { data: dataAmountFunded, loading: loadingAmountFunded, error: errorAmountFunded },
  ] = useLazyQuery(GET_ALL_AMOUNT_FUNDED);
  const [fetchVMSData, { data: dataVMS, loading: loadingVMS, error: errorVMS }] = useLazyQuery(GET_DATA_FOR_VMS);
  const [fetchStoreData, { data: dataStore, loading: loadingStore, error: errorStore }] = useLazyQuery(
    GET_MOCK_DATA_FOR_STORE,
  );
  const [fetchBillingData, { data: dataBilling, loading: loadingBilling, error: errorBilling }] = useLazyQuery(
    GET_AMOUNT_COLLECTED_FOR_BILLING,
  );
  
  useEffect(() => {
    fetchAgencies();
    fetchBureaus({ variables: { agencyCode: '' } });
    fetchCustomerAgencies();
  }, []);

  const fetchAllData = (fy) => {
    if (!fy) return;
    const query = { variables: { fiscalYear: fy } };
    fetchSurcharges(query);
    fetchVMSData();
    fetchAmountFunded(query);
    fetchBillingData(query);
    fetchStoreData(query);
  };

  useEffect(() => {
    fetchAllData(fiscalYear);
  }, [fiscalYear]);

  useEffect(() => {
    if (!dataAgencies || !dataBureaus || !dataCustomerAgencies) return;
    const allBureaus = dataBureaus.getBureaus;
    const agencies = dataAgencies.getAgencies?.map((item) => {
      const bureaus = allBureaus?.filter((b) => b.agencyCode === item.id);
      const indicators = new Set();
      bureaus?.forEach((b) => {
        if (!b.agencyIndicatorCode || b.agencyIndicatorCode === '0') indicators.add(DEFAULT_INDICATOR);
        else indicators.add(b.agencyIndicatorCode.trim());
      });
      if (indicators.size === 0) indicators.add(DEFAULT_INDICATOR);
      return {
        agencyCode: item.id,
        name: item.name,
        shortName: item.shortName,
        indicators: [...indicators],
      };
    });
    setAgencyList(agencies?.sort((a, b) => (a.agencyCode < b.agencyCode ? -1 : 1)));
    fetchAllData(fiscalYear);
  }, [dataAgencies, dataBureaus, dataCustomerAgencies]);

  useEffect(() => {
    if (!agencyList || !dataSurcharges || !dataCustomerAgencies || !customerAgencies) return;
    if (!viewOnly && !(dataAmountFunded && dataVMS && dataStore && dataBilling)) return;

    const rowCal = agencyList
      .map((agencyInfo) =>
        MergeAgencyData(
          viewOnly,
          fiscalYear,
          agencyInfo,
          {
            dataSurcharges: dataSurcharges.getFinalizedAFVSurcharges,
            dataAmountFunded: dataAmountFunded?.getAFVFYFunds,
            dataVMS: dataVMS?.getVehicleNums,
            dataStore: dataStore?.getMockDataByFY,
            dataBilling: dataBilling?.getBillingLeasingByFY,
          },
          fiscalYear === CURRENT_FY,
        ),
      )
      .filter((row) => row);

    const detailsOptions = agencyList.map((afpAgency) => {
      if (dataCustomerAgencies.getCustomerAgencies.find((ca) => ca.agencyCode === afpAgency.agencyCode)) {
        return {
          agencyCode: afpAgency.agencyCode,
          name: afpAgency.name,
          shortName: afpAgency.shortName,
          indicators: afpAgency.indicators,
        }
      } 
      return undefined;
    }).filter(Boolean);   
    setCustomerAgencies(detailsOptions.sort((a, b) => (a.agencyCode < b.agencyCode ? -1 : 1)));
    setData(
      rowCal.sort((a, b) => {
        return a.agencyCode < b.agencyCode ? -1 : 1;
      }).map((row) => ({
        ...row,
        detailsOptions,
      })),
    );
  }, [dataSurcharges, dataAmountFunded, dataVMS, dataStore, dataBilling]);

  // error handling
  const errorList = [
    errorAgencies,
    errorBureaus,
    errorCustomerAgencies,
    errorSurcharges,
    errorAmountFunded,
    errorVMS,
    errorStore,
    errorBilling,
  ];
  const errorNames = ['agencies', 'bureaus', 'surcharges', 'amount funded', 'vehicles', 'spending', 'collection'];
  useEffect(() => {
    const errors = errorList.map((e, i) => (e ? errorNames[i] : '')).filter((e) => e);
    if (errors.length) {
      setShowError(true);
      setBannerMsg({
        type: 'error',
        message: `Error occured when loading the following data: ${errors.join(', ')}`,
      });
    }
  }, errorList);

  useEffect(() => {
    setFiscalYear(filters.fiscalYear || CURRENT_FY);
  }, [filters.fiscalYear]);

  useEffect(() => {
    let resRows = [];
    const dataToGo = data.sort((a, b) => a.agencyCode < b.agencyCode ? -1 :1);
    if (filters?.agencyCode) {
      resRows = dataToGo.filter((r) => r.agencyCode === filters.agencyCode);
    } if (filters?.agencyCodes?.length) {
      resRows = dataToGo.filter((r) => filters.agencyCodes.includes(r.agencyCode));
    } else if (order?.length) {
      resRows = [...dataToGo].sort((a, b) => {
        const [field, type] = order[0];
        const sortMethod = type === 'ASC' ? 1 : -1;
        return a[field] < b[field] ? -sortMethod : sortMethod;
      });
    } else {
      resRows = [...dataToGo];
    }

    if (!resRows?.length) {
      resRows.push({
        agencyCode: '',
        detailsOptions: '',
        fiscalYear: '',
        fundings: '',
        indicators: [],
        name: '',
        shortName: '',
        totalAmountCollected: '',
        totalAmountFunded: '',
        totalAmountSpent: '',
        totalBalance: '',
        totalNumVehicles: '',
        totalProjectToCollect: '',
        totalProjectToSpend: '',
        totalProjectedBalance: '',
        totalRemainingFunds: '',
        totalSurcharges: '',
      });
    }

    setRowData(resRows);
  }, [data, filters.agencyCode, filters.agencyCodes, order]);

  useEffect(() => {
    if (!rowData?.length) return;

    if (viewOnly) {
      setTableRows([...rowData]);
      return;
    }

    /** add Total row to table */
    const totalRowInit = {
      name: 'Total',
      totalSurcharges: undefined,
      totalAmountFunded: 0.0,
      totalAmountCollected: 0.0,
      totalAmountSpent: 0.0,
      totalBalance: 0.0,
      totalProjectToCollect: 0.0,
      totalProjectToSpend: 0.0,
      totalProjectedBalance: 0.0,
    };
    const totalRow = rowData.reduce(
      (sum, r) => ({
        name: sum.name,
        totalSurcharges: sum.totalSurcharges,
        totalAmountFunded: sum.totalAmountFunded + r.totalAmountFunded,
        totalAmountCollected: sum.totalAmountCollected + r.totalAmountCollected,
        totalAmountSpent: sum.totalAmountSpent + r.totalAmountSpent,
        totalBalance: sum.totalBalance + r.totalBalance,
        totalProjectToCollect: sum.totalProjectToCollect + r.totalProjectToCollect,
        totalProjectToSpend: sum.totalProjectToSpend + r.totalProjectToSpend,
        totalProjectedBalance: sum.totalProjectedBalance + r.totalProjectedBalance,
      }),
      totalRowInit,
    );
    setTableRows([...rowData, totalRow]);
  }, [rowData]);

  const handleSelectedAction = (label, original) => {
    if (label === 'Manage' || label === 'View')
      history.push({
        pathname: `/bm/afv-funding/surcharge`,
        state: {
          detailsOptions: original.detailsOptions,
          fiscalYear: original.fiscalYear,
          agencyCode: original.agencyCode,
        },
      });
  };

  const createRowActions = (cellData, actions) => {
    if(!cellData?.row?.original?.agencyCode) return '';
    if (cellData?.row?.original?.name === 'Total') return '';
    return genRowActions(actions || [], cellData, handleSelectedAction);
  };

  const dollarCell = ({ value, column, row }) => {
    const fundings = row?.original?.fundings;
    
    if ( // get the rates for different indicators
      column.Header === 'Monthly surcharge' &&
      fundings
    ) {
      const rates = fundings?.map((funding) => {
        return funding?.surcharges?.length
          ? funding?.surcharges[funding?.surcharges?.length - 1]
          : null;
      }).filter((entry) => entry);
      const display = [];
      rates.map((rate) => {
        const r = parseFloat(rate.surcharge).toFixed(2);
        const v = rate.agencyIndicator === 'N/A'
        ? `$${r}`
        : `(${rate.agencyIndicator})$${r}`;
        if (rate.agencyIndicator === 'N/A') {
          display.unshift(v);
        } else {
          display.push(v);
        }
        return v;
      });
      return display.join('\n');
    }

    if (!value) {
      if (column.Header === 'Monthly surcharge') return '';
      if (value !== 0) return '--';
    }
    return <span className={value < 0 ? 'minusBalance' : ''}>{formatDollar(value)}</span>;
  };

  const columnsViewOnly = useMemo(
    () => [
      {
        Header: 'Agency',
        accessor: 'name',
        // eslint-disable-next-line
        Cell: (params) => {
          const indicators = params.row.original.indicators?.filter((v) => v !== 'N/A' );
          const indicator = indicators?.length ? `(${indicators.join(',')})` : '';
          const agencyCode = params.row.original?.agencyCode
            ? `(${params.row.original?.agencyCode})${'\u2014'}`
            : '';

          return params.value !== 'Total' ? `${agencyCode}${params.value} ${indicator}` : params.value;
        },
        sortable: false,
      },
      {
        Header: 'Monthly surcharge',
        accessor: 'totalSurcharges',
        sortable: false,
        disableSortBy: true,
        Cell: dollarCell,
        cellClassName: 'cell-right afv-surcharge',
        headerClassName: 'cell-right',
      },
    ],
    [],
  );
  const columnsCommon = [
    {
      Header: 'Agency',
      accessor: 'agencyCode',
      // eslint-disable-next-line
      Cell: (params) => {
        const indicators = params.row.original.indicators?.filter((v) => v !== 'N/A' );
        const indicator = indicators?.length ? `(${indicators.join(',')})` : '';
        const agencyCode = params.row.original?.agencyCode
          ? `(${params.row.original?.agencyCode})${'\u2014'}`
          : '';
        const name = params.row.original?.name;

        return params.value !== 'Total' ? `${agencyCode}${name} ${indicator}` : name;
      },
    },
    {
      Header: 'Monthly surcharge',
      accessor: 'totalSurcharges',
      sortable: false,
      disableSortBy: true,
      Cell: dollarCell,
      cellClassName: 'cell-right afv-surcharge',
      headerClassName: 'cell-right',
    },
  ];
  columnsCommon.push({
    Header: 'Amount funded',
    accessor: 'totalAmountFunded',
    sortable: true,
    Cell: dollarCell,
    cellClassName: 'cell-right',
    headerClassName: 'cell-right',
  });
  const columns = useMemo(() => {
    const cols = [...columnsCommon];
    cols.splice(
      3,
      0,
      {
        Header: 'Projected surcharge to generate',
        accessor: 'totalProjectToCollect',
        sortable: true,
        Cell: dollarCell,
        cellClassName: 'cell-right',
        headerClassName: 'cell-right',
      },
      {
        Header: 'Projected total order incremental',
        accessor: 'totalProjectToSpend',
        sortable: true,
        Cell: () => {
          // TODO: replace this with value
          return '\u2014';
        },
        cellClassName: 'cell-right',
        headerClassName: 'cell-right',
      },
      {
        Header: 'Projected EOY position',
        accessor: 'totalProjectedBalance',
        sortable: true,
        Cell: () => {
          // TODO: replace this with value
          return '\u2014';
        },
        cellClassName: 'cell-right',
        headerClassName: 'cell-right',
      },
      {
        Header: 'Actions',
        sortable: false,
        // eslint-disable-next-line
        Cell: (cellData) => createRowActions(
          cellData,
          !viewOnly && !isOrderingAdminRole
            ? ROW_ACTIONS.current
            : ROW_ACTIONS.past
        ),
        cellClassName: 'cell-center',
        headerClassName: 'cell-center',
      },
    );
    return cols;
  }, []);

  const columnsPastFY = useMemo(() => {
    const cols = [...columnsCommon];
    cols.splice(
      3,
      0,
      {
        Header: 'Funds collected',
        accessor: 'totalAmountCollected',
        sortable: true,
        Cell: dollarCell,
        cellClassName: 'cell-right',
        headerClassName: 'cell-right',
      },
      {
        Header: 'Funds spent',
        accessor: 'totalAmountSpent',
        sortable: true,
        Cell: dollarCell,
        cellClassName: 'cell-right',
        headerClassName: 'cell-right',
      },
      {
        Header: 'Balance',
        accessor: 'totalBalance',
        sortable: true,
        Cell: dollarCell,
        cellClassName: 'cell-right',
        headerClassName: 'cell-right',
      },
      {
        Header: 'Actions',
        sortable: false,
        // eslint-disable-next-line
        Cell: (cellData) => createRowActions(cellData, ROW_ACTIONS.past),
        cellClassName: 'cell-center',
        headerClassName: 'cell-center',
      },
    );
    return cols;
  }, []);

  const onExportCSV = () => {
    if (!tableRows?.length) return;
    let cols;
    if (viewOnly) cols = columnsViewOnly;
    else cols = (fiscalYear >= CURRENT_FY ? columns : columnsPastFY).slice(0, 6);
    const headers = cols.map((col) => col.Header);
    const accessors = cols.map((col) => col.accessor);
    tableRows.sort((a, b) => (a[DEFAULT_CSV_ORDER_BY] < b[DEFAULT_CSV_ORDER_BY] ? -1 : 1));
    const items = tableRows
      .map((row) =>
        accessors.map((acc) => {
          let val = row[acc];
          if ( // afp-165373 hide those 2 columns for now
            acc === 'totalProjectToSpend' ||
            acc === 'totalProjectedBalance'
          ) {
            return '';
          }
          if (acc === 'agencyCode') {
            val = `(${val}) - ${row.name}`;
          }
          if (
            acc === 'totalSurcharges' &&
            row.fundings?.length > 1
          ) {
            const funds = row.fundings.map((fund) => {
              const prefix = fund.agencyIndicator === 'N/A'
                ? ''
                : `(${fund.agencyIndicator})`;
              if (fund.fiscalYear !== CURRENT_FY) {
                return '';
              }
              const currentSurcharge = fund.surcharges?.length
                ? parseFloat(fund.surcharges[fund.surcharges?.length-1]?.surcharge).toFixed(2)
                : '';
              return `${prefix}$${currentSurcharge}`;
            });
            return funds.sort().join(', ');
          }
          if (!val && Number.isNaN(val)) return '';
          return typeof val === 'number' ? val.toFixed(2) : val;
        }),
      );
    exportCSVFile(headers, items, `AFV Funding Summary ${fiscalYear}`);
  };

  const isLoading =
    loadingAgencies ||
    loadingBureaus ||
    loadingCustomerAgencies ||
    loadingSurcharges ||
    loadingAmountFunded ||
    loadingVMS ||
    loadingStore ||
    loadingBilling;
  return (
    <AFVFundingFilterProvider>
      <div className="margin-bottom-6">
        <Button
          id="toggle-filter-btn"
          data-testid="toggle-filter-btn"
          leftIcon={{name: 'hide_filters', className:"margin-left-1 margin-right-1 text-middle" }}
          label={showFilters ? 'Hide Filters' : 'Show filters'}
          onClick={() => { setShowFilters( !showFilters ) }}
          variant="outline"
          style={{
            position: 'relative',
            top: '-1.5rem',
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '-64px 0 16px 0',
          }}
        >
          <Button
            id="export-csv-btn"
            data-testid="export-csv-btn"
            onClick={onExportCSV}
            leftIcon={{ name: 'file_download' }}
            label="Export to CSV"
          />
        </div>
        <div className="grid-row grid-gap">
          {
            showFilters
              ? 
                <div
                  className="desktop:grid-col-3 tablet-lg:grid-col-3 afv-funding-filter-container standard-table-filters-wrapper margin-top-2"
                  data-testid="afv-funding-filter-container"
                >
                  { !loadingAgencies && customerAgencies?.length
                    ? <AFVFundingSidebar
                        agencyList={customerAgencies}
                        onFiltersChange={(pFilters) => {
                          // get fiscal year and agencyCode from the filter
                          let fiscalYearValue = '';
                          let agencyCodes = [];
                          pFilters.forEach((filter) => {
                            if (filter.key === '$fiscal_year$') {
                              fiscalYearValue = filter.value;
                            } else {
                              agencyCodes = filter.value;
                            }
                          });
                          setFilters((prev) => ({ ...prev, fiscalYear: fiscalYearValue, agencyCodes }));
                        }}
                    />
                    : <></>}
                </div>
              : <></>
          }
          {viewOnly? (
            <div className={showFilters ? 'desktop:grid-col-9 afv-funding-table-viewonly-container tablet-lg:grid-col-9' : 'desktop:grid-col-12 afv-funding-table-viewonly-container tablet-lg:grid-col-12'}>
              <AfpTable
                id="table-view-only"
                columns={columnsViewOnly}
                isLoading={isLoading}
                onError={showError}
                NoDataMessages={{
                  title: 'No Data Available',
                  text: 'There are no matches for the filtered values at left.',
                }}
                data={tableRows}
                onSort={onSort}
                fullWidth
              />
            </div>
          ) : (
            <div className={showFilters ? 'desktop:grid-col-9 afv-funding-table-container tablet-lg:grid-col-9' : 'desktop:grid-col-12 afv-funding-table-container tablet-lg:grid-col-12'}>
              {fiscalYear >= CURRENT_FY && (
                <AfpTable
                  id="table-current-fy"
                  NoDataMessages={{
                    title: 'No Data Available',
                    text: 'There are no matches for the filtered values at left.',
                  }}
                  isLoading={isLoading}
                  onError={showError}
                  columns={columns}
                  data={tableRows}
                  onSort={onSort}
                  fullWidth
                />
              )}
              {fiscalYear < CURRENT_FY && (
                <AfpTable
                  id="table-past-fy"
                  columns={columnsPastFY}
                  isLoading={isLoading}
                  onError={showError}
                  NoDataMessages={{
                    title: 'No Data Available',
                    text: 'There are no matches for the filtered values at left.',
                  }}
                  data={tableRows}
                  onSort={onSort}
                  fullWidth
                />
              )}
            </div>
          )}
        </div>
      </div>
    </AFVFundingFilterProvider>
  );
};

AFVFundingPage.propTypes = {
  setBannerMsg: PropTypes.func.isRequired,
};

export default AFVFundingPage;
