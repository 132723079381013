import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { useAFVFundingFilter } from './filter-provider';
import { generateAFVFundingFilterStructure } from './afv-funding-filter-structure';

const AFVFundingSidebar = ({
    agencyList,
    onFiltersChange
  }) => {
  const { filterStructure, setFilters, setStructure } = useAFVFundingFilter();

  const setAFVFundingFilters = (conditions) => {
    setFilters(conditions);
  };

  useEffect(() => {
    setStructure(generateAFVFundingFilterStructure(agencyList));
  }, []);

  if (filterStructure?.length > 0) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={filterStructure}
        setQueryFiltersState={
          (filters) => {
            onFiltersChange(filters);
            return setAFVFundingFilters(filters);
          }
        }
        clearButtonLabel="Reset all"
        showClearIcon
        order={[['updateAt', 'DESC']]}
        showSearchIcon
      />
    );
  }
  return null;
};

AFVFundingSidebar.propTypes = {
  agencyList: PropTypes.isRequired,
  onFiltersChange: PropTypes.isRequired,
};

export default AFVFundingSidebar;
