import React, { useEffect } from 'react';
import { dispatchBannerMessage } from 'utilities/componentUtils';
import { ResponsiveFilterPanel, Button, useModal } from '@gsa/afp-component-library';
import { Can } from '@gsa/afp-shared-ui-utils';
import { ENTITY, ROLE_OP } from 'components/role-permission/role-permission';
import LoadingQueryResult from 'components/wrappers/LoadingQueryResult';
import { useBoolToggle } from 'hooks/useBoolToggle';
import { getFilterItems } from '../model/service/schemas/filterSchema';
import useDataController from '../model/service/useDataController';
import { serviceFilterOnChange } from '../helper';
import ServiceTable from '../components/service/ServiceTable';
import { handleSelectedAction } from '../model/service/schemas/serviceTableSchema';
import ServiceModals from '../components/service/ServiceModals';


// eslint-disable-next-line react/prop-types
const ServiceContainer = ({ useTrackedState, useDispatch, DISPATCH_FNS }) => {
  // use openModal and closeModal was used for change the style
  // the change style behavior has been removed it becomes remove which make useModal as a redundant method need to refactor it
  const { openModal, closeModal } = useModal();
  const [isPaginationComponentReset, resetPaginationComponent] = useBoolToggle();
  const setDispatch = useDispatch();
  const pageState = useTrackedState();
  const { query, loading, mutation } = useDataController({
    dispatchBannerMessage,
    setDispatch,
    DISPATCH_FNS,
    pageState,
    closeModal,
  });

  const { initialLoading, fetchServiceRows, getServiceNameAndStartDateThenUpdate } = query;

  useEffect(() => {
    // fetchServiceRows will be trigger on onComplete function of fetchDistinctOptInSalesCode
    initialLoading();
  }, []);

  return (
    <>
      <LoadingQueryResult loadingObject={{ ...loading, pageLoading: pageState.serviceFilter.isLoading }}>
        <Can I={ROLE_OP.CREATE} a={ENTITY.OPT_IN_SERVICE}>
          <div className="bm_create_category_service_button">
            <Button
              id="create-service-btn"
              data-testid="create-service-btn"
              onClick={() => handleSelectedAction({ setDispatch, DISPATCH_FNS, openModal })('Create', null)}
              leftIcon={{ name: 'add' }}
              label="Create new service"
            />
          </div>
        </Can>
        <div className="grid-row grid-gap margin-bottom-4">
          <div className="desktop:grid-col-2 tablet-lg:grid-col-3 tablet:margin-top-4">
            <ResponsiveFilterPanel
              defaultState={{
                rateStatus: pageState.serviceFilter.rateStatus,
                optInSvcCategory: pageState.serviceFilter.filter.optInSvcCategory,
              }}
              items={getFilterItems(pageState)}
              onChange={serviceFilterOnChange({
                pageState,
                setDispatch,
                fetchServiceRows,
                resetPaginationComponent,
                DISPATCH_FNS,
              })}
            />
          </div>

          <div className="desktop:grid-col-10 tablet-lg:grid-col-9">
            <ServiceTable
              setDispatch={setDispatch}
              DISPATCH_FNS={DISPATCH_FNS}
              pageState={pageState}
              loading={loading}
              openModal={openModal}
              fetchRows={fetchServiceRows}
              isPaginationComponentReset={isPaginationComponentReset}
              resetPaginationComponent={resetPaginationComponent}
            />
          </div>
          <ServiceModals
            setDispatch={setDispatch}
            DISPATCH_FNS={DISPATCH_FNS}
            pageState={pageState}
            loading={loading}
            closeModal={closeModal}
            mutation={mutation}
            getServiceNameAndStartDateThenUpdate={getServiceNameAndStartDateThenUpdate}
          />
        </div>
      </LoadingQueryResult>
    </>
  );
};

export default ServiceContainer;
