import { gql } from '@apollo/client';

export const DISTINCT_OPT_IN_SALES_CODES = gql`
  query getDistinctSalesCodes {
    getDistinctSalesCodes {
      code
      description
    }
  }
`;

export const DISTINCT_OPT_IN_SVC_NAME = gql`
  query getDistinctOptInServiceName {
    getDistinctOptInServiceName {
      optInSvcName
    }
  }
`;

export const DISTINCT_OPT_IN_SVC_CATEGORY = gql`
  query getDistinctOptInServiceCategory {
    getDistinctOptInServiceCategory {
      optInSvcCategory
    }
  }
`;

export const PaginatedOptInServiceResponse = gql`
  fragment PaginatedOptInServiceFields on PaginatedOptInServiceResponse {
    rows {
      optInSvcId
      optInServiceTypeId
      optInServiceType {
        optInSvcCategory
        optInSvcName
      }
      optInSvcDesc
      rateStatus
      startDate
      endDate
      optInSvcSalesId
      optInServiceAssociationObj {
        tierLowerRange
        optInFixedRate
        optInFlatDollar
        optInBillingFreq
      }
      deletedAt
      updatedByUser
      updatedAt
      createdByUser
      lastUpdateUserInfo {
        fullName
        email
      }
      comment
      createdAt
      dataSource
      dmlFlag
    }
    count
    hasMore
  }
`;

export const GET_OPT_IN_SERVICE = gql`
  query getOptInService(
    $associationFilter: FilterType
    $virtualFilter: FilterType
    $filters: FilterType
    $offset: Int
    $limit: Int
    $order: OrderBy
  ) {
    getOptInService(
      associationFilter: $associationFilter
      virtualFilter: $virtualFilter
      filters: $filters
      offset: $offset
      limit: $limit
      order: $order
    ) {
      ...PaginatedOptInServiceFields
    }
  }
  ${PaginatedOptInServiceResponse}
`;

export const OptInServiceResponse = gql`
  fragment OptInServiceResponseFields on OptInService {
    optInSvcId
    optInSvcDesc
    rateStatus
    optInSvcSalesId
    optInServiceType {
      optInSvcCategory
      optInSvcName
    }
  }
`;

export const GET_DIST_SERVICE_NAME_AND_START_DATE = gql`
  query getDistinctOptInServiceNameAndStartDate($optInSvcName: String!, $startDate: String!) {
    getDistinctOptInServiceNameAndStartDate(optInSvcName: $optInSvcName, startDate: $startDate) {
      ...OptInServiceResponseFields
    }
  }
  ${OptInServiceResponse}
`;

export const CREATE_NEW_OPT_IN_SERVICE = gql`
  mutation createOptInSvc($optInSvcInput: OptInSvcInput!, $optInSvcRatesAssoc: OptInSvcRatesAssoc!) {
    createOptInSvc(optInSvcInput: $optInSvcInput, optInSvcRatesAssoc: $optInSvcRatesAssoc) {
      optInSvcSalesId
      startDate
      endDate
      optInServiceType {
        optInSvcCategory
        optInSvcName
      }
    }
  }
`;

export const DEACTIVATE_OPT_IN_SERVICE = gql`
  mutation deactivateOptInService(
    $optInSvcId: String!
    $optInSvcName: String!
    $rateStatus: String!
    $comment: String!
  ) {
    deactivateOptInService(
      optInSvcId: $optInSvcId
      optInSvcName: $optInSvcName
      rateStatus: $rateStatus
      comment: $comment
    ) {
      ...OptInServiceResponseFields
    }
  }
  ${OptInServiceResponse}
`;

export const DELETE_OPT_IN_SERVICE = gql`
  mutation deleteOptInSvc($optInSvcId: String!, $rateStatus: String!, $optInSvcInput: OptInSvcInput!) {
    deleteOptInSvc(optInSvcId: $optInSvcId, rateStatus: $rateStatus, optInSvcInput: $optInSvcInput) {
      ...OptInServiceResponseFields
    }
  }
  ${OptInServiceResponse}
`;

export const UPDATE_OPT_IN_SERVICE = gql`
  mutation updateOptInSvc(
    $optInSvcId: String!
    $optInSvcInput: OptInSvcInput!
    $optInSvcRatesAssoc: OptInSvcRatesAssoc!
  ) {
    updateOptInSvc(optInSvcId: $optInSvcId, optInSvcInput: $optInSvcInput, optInSvcRatesAssoc: $optInSvcRatesAssoc) {
      ...OptInServiceResponseFields
    }
  }
  ${OptInServiceResponse}
`;

export const REACTIVATE_OPT_IN_SERVICE = gql`
  mutation reactivateOptInService(
    $optInSvcId: String!
    $optInSvcName: String!
    $rateStatus: String!
    $comment: String!
  ) {
    reactivateOptInService(
      optInSvcId: $optInSvcId
      optInSvcName: $optInSvcName
      rateStatus: $rateStatus
      comment: $comment
    ) {
      ...OptInServiceResponseFields
    }
  }
  ${OptInServiceResponse}
`;